import React from "react";
import theme from "theme";
import { Theme, Link, Image, Section, Box, Strong, Text, Hr, Span, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Pienkuormaaja ja työkone rakennusalan tarpeisiin - Samkone - castloader - castloaders - kauhakuormaaja - sähkökuormaaja - minikuormaaja
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:title"} content={"Pienkuormaaja ja työkone rakennusalan tarpeisiin - Samkone - castloader - castloaders - kauhakuormaaja - sähkökuormaaja - minikuormaaja"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Section
			id="koti"
			background="#504f4f radial-gradient(circle at center,rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%)"
			lg-display="inline-block"
			lg-height="50% content-box"
			md-padding="10px 0 10px 0"
			md-position="static"
			sm-display="flex"
			sm-height="auto"
			sm-width="auto"
		>
			<Override
				slot="SectionContent"
				display="flex"
				lg-justify-content="space-between"
				lg-align-items="center"
				lg-flex-direction="row"
				lg-display="block"
			/>
			<Box
				display="flex"
				padding="0px 0"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
				md-justify-content="flex-start"
			>
				<Image
					lg-max-height="400px"
					width="25%"
					max-height="450px"
					md-margin="0px 0px 0 0px"
					src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamLogoIlmanKauhaa.png?v=2023-04-28T10:44:02.412Z"
					lg-position="relative"
					lg-left="-5px"
					max-width="100%"
					flex="0 1 auto"
					order="0"
					height="100% content-box"
					lg-align-self="auto"
					lg-order="0"
					lg-flex="0 0 auto"
					md-display="block"
					md-min-width="50%"
					sm-margin="0px 0px 0 0px"
					srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamLogoIlmanKauhaa.png?v=2023-04-28T10%3A44%3A02.412Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamLogoIlmanKauhaa.png?v=2023-04-28T10%3A44%3A02.412Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamLogoIlmanKauhaa.png?v=2023-04-28T10%3A44%3A02.412Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamLogoIlmanKauhaa.png?v=2023-04-28T10%3A44%3A02.412Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamLogoIlmanKauhaa.png?v=2023-04-28T10%3A44%3A02.412Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamLogoIlmanKauhaa.png?v=2023-04-28T10%3A44%3A02.412Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamLogoIlmanKauhaa.png?v=2023-04-28T10%3A44%3A02.412Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Section display="block" md-padding="10px 0 24px 0">
					<Override
						slot="SectionContent"
						display="block"
						text-align="center"
						padding="0px 15px 0px 15px"
						sm-display="grid"
					/>
					<Components.LinkkiMallit>
						Mallit
					</Components.LinkkiMallit>
					<Components.LinkkiJalleenmyyjat>
						Jälleenmyyjät
					</Components.LinkkiJalleenmyyjat>
					<Components.LinkkiYhtTiedot>
						Yhteystiedot
					</Components.LinkkiYhtTiedot>
				</Section>
			</Box>
		</Section>
		<Section
			background="linear-gradient(264.47deg,#dcd7d2 29.39%,#ea4a00 93.49%)"
			padding="36px 0 80px 12px"
			quarkly-title="HeroBlock"
			md-padding="36px 0 60px 0"
			hover-background="linear-gradient(264.47deg,#dcd7d2 29.39%,#ea4a00 93.49%) scroll"
			lg-flex-direction="row-reverse"
			sm-background="linear-gradient(264.47deg,#d0a983 19%,#ea4a00 93.49%)"
			md-background="linear-gradient(264.47deg,#d0a983 29.39%,#ea4a00 93.49%)"
			lg-background="linear-gradient(264.47deg,#d0a983 29.39%,#ea4a00 93.49%)"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				position="relative"
				z-index="9"
				md-flex-wrap="wrap"
				md-z-index="7"
			/>
			<Box
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				flex-direction="column"
				display="flex"
				align-items="flex-start"
				lg-padding="0px 90px 0px 0px"
				md-width="100%"
				justify-content="center"
				width="60%"
				padding="0px 180px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text
					lg-text-align="left"
					lg-font="normal 700 40px/1.2 &quot;Inter&quot;, sans-serif"
					md-font="normal 700 28px/1.2 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--white"
					sm-font="normal 500 30px/1.2 &quot;Inter&quot;, sans-serif"
					sm-text-align="center"
					md-text-align="center"
					width="130%"
					md-width="100%"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Työkone ammattikäyttöön!{" "}
					</Strong>
				</Text>
				<Text
					font="--base"
					lg-text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Inter&quot;, sans-serif"
					md-font="normal 400 14px/1.5 &quot;Inter&quot;, sans-serif"
					margin="0px 0px 0px 0px"
					color="--white"
					opacity="1"
					lg-border-radius="8px"
					md-text-align="center"
					md-max-width="max-content"
					md-min-width="100%"
				>
					Virallinen castloaders maahantuoja.
					<br />
					Pienkuormaaja juuri sinun tarpeisiin.
				</Text>
			</Box>
			<Image
				src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33tlxEsiteNobg.png?v=2023-05-04T08:42:07.632Z"
				display="block"
				hover-background="rgba(0, 0, 0, 0)"
				transition="background --transitionDuration-normal --transitionTimingFunction-easeOut"
				hover-flex="0 1 auto"
				flex="0 1 auto"
				srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33tlxEsiteNobg.png?v=2023-05-04T08%3A42%3A07.632Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33tlxEsiteNobg.png?v=2023-05-04T08%3A42%3A07.632Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33tlxEsiteNobg.png?v=2023-05-04T08%3A42%3A07.632Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33tlxEsiteNobg.png?v=2023-05-04T08%3A42%3A07.632Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33tlxEsiteNobg.png?v=2023-05-04T08%3A42%3A07.632Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33tlxEsiteNobg.png?v=2023-05-04T08%3A42%3A07.632Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33tlxEsiteNobg.png?v=2023-05-04T08%3A42%3A07.632Z&quality=85&w=3200 3200w"
				sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
		</Section>
		<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/s%C3%A4hk%C3%B6%20pienkuormaaja.jpeg?v=2024-02-05T21:12:04.186Z) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
			sm-align-content="center"
			sm-flex-wrap="wrap"
			sm-justify-content="center"
			sm-align-items="center"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				sm-align-items="center"
				sm-justify-content="center"
				sm-align-self="center"
				sm-align-content="flex-start"
				sm-text-align="center"
				sm-margin="-22px 22px -22px 22px"
				sm-min-width="100%"
				sm-min-height="100%"
				sm-font="22pc sans-serif"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				border-color="#27b927"
				sm-align-self="auto"
				sm-display="flex"
				sm-flex-direction="column"
				sm-justify-content="flex-end"
				sm-width="90%"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--orange"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 16px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
					border-color="#2bbd1d"
					sm-margin="22px 0px 24px 0px"
				>
					Nosta työsi uudelle tasolle - kokeile uutta pienkuormaajaa!
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					sm-font="normal 500 10px/1.6 &quot;Inter&quot;, sans-serif"
					sm-margin="0px 0px -22px 0px"
				>
					Sähkötoiminen pienkuormaajamme ECO800 nyt koeajettavissa Lantmännen Agron Joensuun Liikkeessä
				</Text>
			</Box>
		</Section>
		<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		<Section
			id="mallit"
			lg-padding="60px 0 40px 0"
			padding="60px 0 100px 0"
			md-padding="30px 0 30px 0"
			hover-background="rgba(0, 0, 0, 0) url() 0% 0% /auto repeat scroll padding-box"
			transition="transform --transitionDuration-normal --transitionTimingFunction-easeInOut"
		>
			<Override slot="SectionContent" />
			<Image
				height="100px"
				src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/castloader_logo.png?v=2023-04-27T10:55:18.879Z"
				width="100%"
				max-width="400px"
				flex="0 1 auto"
				margin="0px 0px 30px 0px"
				display="flex"
				align-self="center"
				sm-width="80%"
				srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/castloader_logo.png?v=2023-04-27T10%3A55%3A18.879Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/castloader_logo.png?v=2023-04-27T10%3A55%3A18.879Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/castloader_logo.png?v=2023-04-27T10%3A55%3A18.879Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/castloader_logo.png?v=2023-04-27T10%3A55%3A18.879Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/castloader_logo.png?v=2023-04-27T10%3A55%3A18.879Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/castloader_logo.png?v=2023-04-27T10%3A55%3A18.879Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/castloader_logo.png?v=2023-04-27T10%3A55%3A18.879Z&quality=85&w=3200 3200w"
				sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
			/>
			<Text
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 40px 0px"
				margin="0px 0px 80px 0px"
				font="--headline4"
				color="#d75e27"
				lg-font="normal 700 34px/1.2 &quot;Inter&quot;, sans-serif"
				md-font="normal 700 20px/1.2 &quot;Inter&quot;, sans-serif"
				width="auto"
				text-decoration-line="underline"
				text-align="center"
			>
				Koko Cast mallistossa on sama standardimallinen työlaitekiinnitys, sekä työhydrauliikka kuin useimmilla valmistajilla
			</Text>
			<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
			>
				<Box md-width="100%" width="50%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/maxo57Nelio.jpg?v=2023-05-03T14:22:16.881Z"
						alt="pienkuormaaja avant vastaava samkone malli"
						object-fit="cover"
						width="auto"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						height="auto"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/maxo57Nelio.jpg?v=2023-05-03T14%3A22%3A16.881Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/maxo57Nelio.jpg?v=2023-05-03T14%3A22%3A16.881Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/maxo57Nelio.jpg?v=2023-05-03T14%3A22%3A16.881Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/maxo57Nelio.jpg?v=2023-05-03T14%3A22%3A16.881Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/maxo57Nelio.jpg?v=2023-05-03T14%3A22%3A16.881Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/maxo57Nelio.jpg?v=2023-05-03T14%3A22%3A16.881Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/maxo57Nelio.jpg?v=2023-05-03T14%3A22%3A16.881Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 5% 32px 64px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="50%"
					flex-direction="column"
					justify-content="space-around"
					align-items="center"
					border-radius="none"
					sm-padding="32px 0 32px 0px"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline3"
						color="#ea4a00"
						width="wrap-content"
						height="wrap-content"
						lg-font="600 40px/1.3 &quot;Inter&quot;, sans-serif"
						text-align="center"
						sm-margin="15px 0px 15px 0px"
						padding="0px 0px 10px 0px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							50-sarja
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nostovoima
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}2100 kg
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Moottoriteho
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								57 hv
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Puomin korkeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							font-weight="400"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								3,30 m
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ajonopeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}10-30 km/h
							</Strong>
						</Span>
					</Text>
					<Link
						href="https://www.dropbox.com/s/goqjn3moq82e9ab/cast50%20uusi.pdf?raw=1"
						color="#ffffff"
						background="#d75e27"
						font="--headline4"
						text-decoration-line="initial"
						border-color="#d75e27"
						border-width="10px"
						border-style="solid"
						border-radius="15px 10px 20px 5px"
					>
						Lue lisää
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
			>
				<Box md-width="100%" width="50%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/40SarjaNelio.jpg?v=2023-05-03T09:24:01.780Z"
						alt="Avant kopio malli pienkuormaaja samkone"
						object-fit="cover"
						width="auto"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						height="auto"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/40SarjaNelio.jpg?v=2023-05-03T09%3A24%3A01.780Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/40SarjaNelio.jpg?v=2023-05-03T09%3A24%3A01.780Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/40SarjaNelio.jpg?v=2023-05-03T09%3A24%3A01.780Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/40SarjaNelio.jpg?v=2023-05-03T09%3A24%3A01.780Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/40SarjaNelio.jpg?v=2023-05-03T09%3A24%3A01.780Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/40SarjaNelio.jpg?v=2023-05-03T09%3A24%3A01.780Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/40SarjaNelio.jpg?v=2023-05-03T09%3A24%3A01.780Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 5% 32px 64px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="50%"
					flex-direction="column"
					justify-content="space-around"
					align-items="center"
					border-radius="none"
					sm-padding="32px 0 32px 0px"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline3"
						color="#ea4a00"
						width="wrap-content"
						height="wrap-content"
						lg-font="600 40px/1.3 &quot;Inter&quot;, sans-serif"
						text-align="center"
						sm-margin="15px 0px 15px 0px"
						padding="0px 0px 10px 0px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							40-sarja
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nostovoima
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}1500 kg
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Moottoriteho
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								38 hv
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Puomin korkeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							font-weight="400"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								3,30 m
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ajonopeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong>
								{"  "}16 km/h
							</Strong>
						</Span>
					</Text>
					<Link
						href="https://www.dropbox.com/s/ynrdkd35tehbjow/Cast40sarja%20uus.pdf?raw=1"
						color="#ffffff"
						background="#d75e27"
						font="--headline4"
						text-decoration-line="initial"
						border-color="#d75e27"
						border-width="10px"
						border-style="solid"
						border-radius="15px 10px 20px 5px"
					>
						Lue lisää
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 0px 0px"
			>
				<Box md-width="100%" width="50%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33TLXNelio.jpg?v=2023-05-04T10:18:18.710Z"
						alt="pienkuormaaja castloaders avant kopio samkone"
						object-fit="cover"
						width="auto"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						height="auto"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33TLXNelio.jpg?v=2023-05-04T10%3A18%3A18.710Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33TLXNelio.jpg?v=2023-05-04T10%3A18%3A18.710Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33TLXNelio.jpg?v=2023-05-04T10%3A18%3A18.710Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33TLXNelio.jpg?v=2023-05-04T10%3A18%3A18.710Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33TLXNelio.jpg?v=2023-05-04T10%3A18%3A18.710Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33TLXNelio.jpg?v=2023-05-04T10%3A18%3A18.710Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/33TLXNelio.jpg?v=2023-05-04T10%3A18%3A18.710Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 5% 32px 64px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 0 32px 0px"
					width="50%"
					flex-direction="column"
					justify-content="space-around"
					align-items="center"
					border-radius="none"
					sm-padding="32px 0 32px 0px"
				>
					<Text
						margin="0px 0px 0px 0px"
						font="--headline3"
						color="#ea4a00"
						width="wrap-content"
						height="wrap-content"
						lg-font="600 40px/1.3 &quot;Inter&quot;, sans-serif"
						text-align="center"
						sm-margin="15px 0px 15px 0px"
						padding="0px 0px 10px 0px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							30-sarja
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nostovoima
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}1180 kg
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Moottoriteho
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								26 hv
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Puomin korkeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							font-weight="400"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								2,98 m
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ajonopeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}12-20 km/h
							</Strong>
						</Span>
					</Text>
					<Link
						href="https://www.dropbox.com/s/936sv05zwhhsm5f/Cast30sarjaUus.pdf?raw=1"
						color="#ffffff"
						background="#d75e27"
						font="--headline4"
						text-decoration-line="initial"
						border-color="#d75e27"
						border-width="10px"
						border-style="solid"
						border-radius="15px 10px 20px 5px"
					>
						Lue lisää
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
			>
				<Box md-width="100%" width="50%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800Nelio.jpg?v=2023-05-03T08:27:32.197Z"
						alt="samkone pienkuormaaja avant kopio"
						object-fit="cover"
						width="auto"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						height="auto"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800Nelio.jpg?v=2023-05-03T08%3A27%3A32.197Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800Nelio.jpg?v=2023-05-03T08%3A27%3A32.197Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800Nelio.jpg?v=2023-05-03T08%3A27%3A32.197Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800Nelio.jpg?v=2023-05-03T08%3A27%3A32.197Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800Nelio.jpg?v=2023-05-03T08%3A27%3A32.197Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800Nelio.jpg?v=2023-05-03T08%3A27%3A32.197Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800Nelio.jpg?v=2023-05-03T08%3A27%3A32.197Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 5% 32px 64px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="50%"
					flex-direction="column"
					justify-content="space-around"
					align-items="center"
					border-radius="none"
					sm-padding="15px 0 32px 0px"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline3"
						color="#ea4a00"
						width="wrap-content"
						height="wrap-content"
						lg-font="600 40px/1.3 &quot;Inter&quot;, sans-serif"
						text-align="center"
						sm-margin="16px 0px 15px 0px"
						padding="0px 0px 10px 0px"
						sm-font="normal 400 49px/1.2 --fontFamily-googleInter"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							800-sarja
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nostovoima
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong>
								{"  "}1300 kg
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Moottoriteho
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong>
								26 hv
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nostokorkeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							font-weight="400"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong>
								2,98 m
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ajonopeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong>
								{"  "}16 km/h
							</Strong>
						</Span>
					</Text>
					<Link
						href="https://www.dropbox.com/s/3sylhc2ahxhkead/800%20sarja%20uusi.pdf?raw=1"
						color="#ffffff"
						background="#d75e27"
						font="--headline4"
						text-decoration-line="initial"
						border-color="#d75e27"
						border-width="10px"
						border-style="solid"
						border-radius="15px 10px 20px 5px"
					>
						Lue lisää
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
			>
				<Box md-width="100%" width="50%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800EcoNelio.jpg?v=2023-05-03T08:57:26.628Z"
						alt="samkone malli avant kopio"
						object-fit="cover"
						width="auto"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						height="auto"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800EcoNelio.jpg?v=2023-05-03T08%3A57%3A26.628Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800EcoNelio.jpg?v=2023-05-03T08%3A57%3A26.628Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800EcoNelio.jpg?v=2023-05-03T08%3A57%3A26.628Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800EcoNelio.jpg?v=2023-05-03T08%3A57%3A26.628Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800EcoNelio.jpg?v=2023-05-03T08%3A57%3A26.628Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800EcoNelio.jpg?v=2023-05-03T08%3A57%3A26.628Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/800EcoNelio.jpg?v=2023-05-03T08%3A57%3A26.628Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 5% 32px 64px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="50%"
					flex-direction="column"
					justify-content="space-around"
					align-items="center"
					border-radius="none"
					sm-padding="15px 0 32px 0px"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline3"
						color="#23b715"
						width="wrap-content"
						height="wrap-content"
						lg-font="600 40px/1.3 &quot;Inter&quot;, sans-serif"
						text-align="center"
						sm-margin="16px 0px 15px 0px"
						padding="0px 0px 10px 0px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							800 Eco-sarja
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font="700 32px/25.6px Inter, sans-serif"
						>
							Sähkökuormaaja
						</Strong>
					</Text>
					<br />
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nostovoima
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#23b715">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}1400 kg
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Sähkömoottori
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#23b715">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								15 Kw
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nostokorkeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#23b715">
						<Span
							font-weight="400"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong>
								2,98 m
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ajonopeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#23b715">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}12 km/h
							</Strong>
						</Span>
					</Text>
					<Link
						href="https://www.dropbox.com/s/o085uxae79nh0zj/800eco%20uusi.pdf?raw=1"
						color="#ffffff"
						background="#23b715"
						font="--headline4"
						text-decoration-line="initial"
						border-color="#23b715"
						border-width="10px"
						border-style="solid"
						border-radius="15px 10px 20px 5px"
					>
						Lue lisää
					</Link>
				</Box>
			</Box>
			<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
			<Box
				align-self="center"
				flex="1 1 0%"
				min-height="max-content"
				min-width="max-content"
				width="70% border-box"
				height="70% border-box"
				sm-flex="0 1 auto"
				sm-max-width="50%"
				sm-max-height="50%"
				sm-display="grid"
				sm-min-width="100%"
				padding="5px 0px 5px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/logo-mini.png?v=2023-05-05T07:04:35.670Z"
					display="block"
					sm-min-height="30%"
					sm-align-self="center"
					sm-justify-self="center"
					sm-width="280px"
					sm-height="50% content-box"
					sm-min-width="30%"
					sm-order="0"
					sm-display="flex"
					md-width="400px"
					srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/logo-mini.png?v=2023-05-05T07%3A04%3A35.670Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/logo-mini.png?v=2023-05-05T07%3A04%3A35.670Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/logo-mini.png?v=2023-05-05T07%3A04%3A35.670Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/logo-mini.png?v=2023-05-05T07%3A04%3A35.670Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/logo-mini.png?v=2023-05-05T07%3A04%3A35.670Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/logo-mini.png?v=2023-05-05T07%3A04%3A35.670Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/logo-mini.png?v=2023-05-05T07%3A04%3A35.670Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
			<Box
				display="flex"
				width="100%"
				border-style="solid"
				border-radius="10px"
				min-height="284px"
				md-border-radius="0px"
				margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
				padding="0px 0px 0px 0px"
				border-color="rgba(66, 82, 207, 0.1)"
				border-width="2px"
				flex-wrap="wrap"
				md-border-width="0px 0px 2px 0px"
			>
				<Box md-width="100%" width="50%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-22-loaderNelio.jpg?v=2023-05-05T07:02:53.847Z"
						alt="malli samkone avant kopio"
						object-fit="cover"
						width="auto"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						height="100%"
						align-self="center"
						srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-22-loaderNelio.jpg?v=2023-05-05T07%3A02%3A53.847Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-22-loaderNelio.jpg?v=2023-05-05T07%3A02%3A53.847Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-22-loaderNelio.jpg?v=2023-05-05T07%3A02%3A53.847Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-22-loaderNelio.jpg?v=2023-05-05T07%3A02%3A53.847Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-22-loaderNelio.jpg?v=2023-05-05T07%3A02%3A53.847Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-22-loaderNelio.jpg?v=2023-05-05T07%3A02%3A53.847Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-22-loaderNelio.jpg?v=2023-05-05T07%3A02%3A53.847Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box md-width="100%" width="50%" display="flex" lg-width="50%">
					<Image
						margin="-1px 0px -1px -1px"
						src="https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-wx22Nelio.jpg?v=2023-05-05T07:01:30.738Z"
						alt="samkone avant kopio malli"
						object-fit="cover"
						width="auto"
						border-radius="10px"
						md-margin="0px 0px 0px 0px"
						height="100%"
						align-self="center"
						flex="1 1 0%"
						srcSet="https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-wx22Nelio.jpg?v=2023-05-05T07%3A01%3A30.738Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-wx22Nelio.jpg?v=2023-05-05T07%3A01%3A30.738Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-wx22Nelio.jpg?v=2023-05-05T07%3A01%3A30.738Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-wx22Nelio.jpg?v=2023-05-05T07%3A01%3A30.738Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-wx22Nelio.jpg?v=2023-05-05T07%3A01%3A30.738Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-wx22Nelio.jpg?v=2023-05-05T07%3A01%3A30.738Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/ssq-wx22Nelio.jpg?v=2023-05-05T07%3A01%3A30.738Z&quality=85&w=3200 3200w"
						sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					md-width="100%"
					padding="32px 5% 32px 64px"
					display="flex"
					lg-width="50%"
					position="relative"
					lg-padding="32px 30px 32px 32px"
					md-padding="32px 30px 32px 0px"
					width="100%"
					flex-direction="column"
					justify-content="space-around"
					align-items="center"
					border-radius="none"
					sm-padding="15px 0 32px 0px"
					align-self="center"
					lg-display="flex"
					lg-justify-content="space-around"
					lg-text-align="center"
					flex="1 1 0%"
				>
					<Text
						margin="0px 0px 8px 0px"
						font="--headline3"
						color="#ea4a00"
						width="wrap-content"
						height="wrap-content"
						text-align="center"
						sm-margin="16px 0px 15px 0px"
						padding="0px 0px 10px 0px"
						sm-font="normal 400 49px/1.2 --fontFamily-googleInter"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Mini-sarja
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--lead" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Diesel-, bensiini- ja sähkökäyttöiset{" "}
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Nostovoima
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}280-450 kg
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Moottoriteho
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								12-22 hv / 5,7 Kw{" "}
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Puomin korkeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							font-weight="400"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								2,04 - 2,11 m
							</Strong>
						</Span>
					</Text>
					<Text margin="0px 0px 0px 0px" font="--textBase" color="--darkL1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Ajonopeus
						</Strong>
					</Text>
					<Text margin="0px 0px 16px 0px" font="--headline4" color="#ea4a00">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							font-weight="normal"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								{"  "}0-6 km/h
							</Strong>
						</Span>
					</Text>
					<Link
						href="https://www.dropbox.com/s/jl3jxklccb682cw/CastMini%20uusi.pdf?raw=1"
						color="#ffffff"
						background="#d75e27"
						font="--headline4"
						text-decoration-line="initial"
						border-color="#d75e27"
						border-width="10px"
						border-style="solid"
						border-radius="15px 10px 20px 5px"
					>
						Lue lisää
					</Link>
				</Box>
			</Box>
			<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		</Section>
		<Section id="jalleenmyyjat" padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/Image%20%289%29.png?v=2021-08-26T14:56:08.732Z) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="100%"
					display="block"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
					height="100%"
					text-align="center"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Jälleenmyyjät
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--lightgreen" font="--headline4" lg-text-align="left">
						Karelia Agro Oy
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--textBase" lg-text-align="left">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Em
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									{"\n"}Joensuu,Juuka,Pieksamäki{"\n\n"}
								</Em>
								<br />
								Kimmo Pennanen
							</Strong>
							<br />
							0503112071
							<br />
							kimmo.pennanen@lantmannenagro.fi
							<br />
							<br />
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Em
									overflow-wrap="normal"
									word-break="normal"
									white-space="normal"
									text-indent="0"
									text-overflow="clip"
									hyphens="manual"
									user-select="auto"
									pointer-events="auto"
								>
									Nurmes
								</Em>
							</Strong>
							<br />
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Jarmo Korhonen
							</Strong>
							<br />
							0503112080
							<br />
							jarmo.j.korhonen@lantmannenagro.fi
							<br />
							<br />
							<br />
						</Span>
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					align-items="flex-start"
					width="110%"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--lightgreen"
						font="--headline4"
						lg-text-align="left"
						width="100%"
					>
						Veljekset Heiskanen oy
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--textBase" lg-text-align="left">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								<Em>
									Iisalmi
								</Em>
								<br />
								Heikki Rönkkö
							</Strong>
							<br />
							0505792244
							<br />
							heikki.ronkko@lantmannenagro.fi
							<br />
							<br />
							<Strong>
								<Em>
									Kuopio
								</Em>
							</Strong>
							<br />
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Jussi Alakärppä
							</Strong>
							<br />
							0504314563
							<br />
							jussi.alakarppa@lantmannenagro.fi
							<br />
							<Strong>
								Jarmo Koistinen
								<br />
							</Strong>
							0504477721
							<br />
							jarmo.koistinen@lantmannenagro.fi
						</Span>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section id="jalleenmyyjat" padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box
				padding="80px 72px 80px 72px"
				background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/612695d67f2b1f001fa06c1f/images/Image%20%289%29.png?v=2021-08-26T14:56:08.732Z) 0% 0% /cover no-repeat scroll padding-box"
				border-radius="24px"
				margin="0px 0px 64px 0px"
				sm-padding="60px 36px 60px 36px"
			>
				<Box
					width="100%"
					display="block"
					flex-direction="column"
					md-width="100%"
					md-align-items="center"
					md-justify-content="center"
					md-text-align="center"
					lg-width="100%"
					height="100%"
					text-align="center"
				>
					<Text
						margin="0px 0px 16px 0px"
						font="--headline2"
						color="--dark"
						md-text-align="center"
						sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					>
						Huollot
					</Text>
				</Box>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--lightgreen" font="--headline4" lg-text-align="left">
						Joensuu
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--textBase" lg-text-align="left">
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Joen Raskashuolto
							</Strong>
							<br />
							<Strong
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Pekka Leppäkorpi
							</Strong>
							<br />
							0400 124 290
							<br />
							www.joenraskashuolto.fi
							<br />
							<br />
							<br />
						</Span>
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					align-items="flex-start"
					width="110%"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--lightgreen"
						font="--headline4"
						lg-text-align="left"
						width="110%"
					>
						Kuopio
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--textBase" lg-text-align="left">
						<Strong>
							Kari juntunen
						</Strong>
						<br />
						040 718 0471
						<br />
						tmi.juntunen@gmail.com
					</Text>
				</Box>
				<Box
					display="flex"
					flex-direction="column"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					align-items="flex-start"
					width="110%"
				>
					<Text
						margin="0px 0px 18px 0px"
						color="--lightgreen"
						font="--headline4"
						lg-text-align="left"
						width="110%"
					>
						Iisalmi
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--textBase" lg-text-align="left">
						<Strong>
							Aro Jääskeläinen
						</Strong>
						<br />
						040 213 3336
						<br />
						konehuoltojaaskelainen@gmail.com
					</Text>
				</Box>
			</Box>
		</Section>
		<Hr min-height="10px" min-width="100%" margin="0px 0px 0px 0px" />
		<Section id="yhteystiedot" background="--color-dark radial-gradient(circle at center,rgba(0,0,0,0) 8.1%,#1c1c1c 94.3%)" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="--headline3"
				>
					<Strong>
						Yhteystiedot
					</Strong>
				</Text>
				<Link
					href="mailto:info@samkone.fi"
					text-align="center"
					color="--light"
					font="--headline4"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@samkone.fi
				</Link>
				<Link
					href="tel:0451034447"
					text-align="center"
					color="--light"
					font="--headline4"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					0451034447
				</Link>
			</Box>
			<SocialMedia facebook="https://www.facebook.com/profile.php?id=61552918451735" instagram="https://www.instagram.com/samkoneoy/">
				<Override
					slot="link"
					border-radius="50%"
					color="#ffffff"
					hover-color="--light"
					background="transparent"
					hover-background="transparent"
					margin="0 8px"
				/>
				<Override slot="icon" color="#ffffff" />
			</SocialMedia>
			<Link
				href="https://www.linkedin.com/company/samkone-oy/"
				color="--light"
				font="--headline4"
				text-decoration-line="initial"
				margin="0px 0px 16px 0px"
				align-self="center"
			>
				ln
			</Link>
		</Section>
		<Components.QuarklycommunityKitNetlifyForm />
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-T506BMV86T"} place={"endOfBody"} rawKey={"645b4cd0db1aa7f72fc2492e"}>
				{"\n"}
			</script>
			<script place={"endOfBody"} rawKey={"645b4d509c1ffb323781ca06"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-T506BMV86T');"}
			</script>
			<meta
				name={"google-site-verification"}
				http-equiv={""}
				content={"TBhmAX9bJWU_MRqXUBjDxvAawEPQHQzwY4UxogayfX0"}
				charset={""}
				place={"endOfHead"}
				rawKey={"646da5bb3c615338d3fcb769"}
			/>
		</RawHtml>
	</Theme>;
});