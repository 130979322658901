export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"644a2ab68d8a1a00208bae43",
				"644a2ab68d8a1a00208bae47"
			]
		},
		"644a2ab68d8a1a00208bae43": {
			"id": "644a2ab68d8a1a00208bae43",
			"name": "404",
			"pageUrl": "404"
		},
		"644a2ab68d8a1a00208bae47": {
			"id": "644a2ab68d8a1a00208bae47",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_apple_152px": "https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z",
			"favicon_32px": "https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z",
			"background_win10": "#000848",
			"og:title": "Pienkuormaaja ja työkone rakennusalan tarpeisiin - Samkone - castloader - castloaders - kauhakuormaaja - sähkökuormaaja - minikuormaaja",
			"og:description": "",
			"og:image": "https://uploads.quarkly.io/644a2ab68d8a1a00208bae2f/images/SamIlmanKauhaaValkoinenTausta.png?v=2023-04-28T10:51:55.816Z",
			"title": "Pienkuormaaja ja työkone rakennusalan tarpeisiin - Samkone - castloader - castloaders - kauhakuormaaja - sähkökuormaaja - minikuormaaja",
			"description": ""
		}
	}
}