import React from "react";
import { useOverrides } from "@quarkly/components";
import { Link } from "@quarkly/widgets";
const defaultProps = {};

const LinkkiYhtTiedot = props => {
	const {
		children,
		...rest
	} = useOverrides(props, defaultProps);

	const handleClick = event => {
		document.querySelector("#yhteystiedot").scrollIntoView({
			behavior: "smooth"
		});
	};

	return <Link
		{...rest}
		onClick={handleClick}
		color="#d75e27"
		margin="0px 8px 0px 20px"
		transition="opacity 0.5s ease 0.3s"
		font="--headline4"
		text-decoration-line="initial"
		opacity="0.8"
		hover-opacity="1"
		border-color="#d75e27"
	>
		      
		{children}
		    
	</Link>;
};

Object.assign(LinkkiYhtTiedot, { ...Link,
	defaultProps
});
export default LinkkiYhtTiedot;